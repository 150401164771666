export default {
    zh:{
        personInfo:{
            personInfo:'个人信息',
            corp:'所属机构',
            dept:'所属部门',
            position:'职位',
            birthdate:'出生日期',
            sex:'性别',
            baseData:'基本资料',
            baseInfo:'基本信息',
            nickName:'昵称',
            nickName_rule:'请输入昵称',
            mobile:'手机号码',
            email:'电子邮箱',
            save:'保存',
            reset:'重置',
            editPwd:'修改密码',
            prePass:'原密码',
            newPass:'新密码',
            checkPass:'确认密码',
            pass_placeholder:'请输入密码',
            repass_placeholder:'请再次输入密码',
            repass_wrong_placeholder:'两次输入密码不一致!',
        }
    },
    en:{
        personInfo:{
            personInfo:'person Info',
            corp:'Organization',
            dept:'Department',
            position:'position',
            birthdate:'birth date',
            sex:'sex',
            baseData:'base Data',
            baseInfo:'base Info',
            nickName:'nick Name',
            nickName_rule:'Please input nick Name',
            mobile:'mobile',
            email:'email',
            save:'SAVE',
            reset:'RESET',
            editPwd:'Change Password',
            prePass:'Original password',
            newPass:'New password',
            checkPass:'Confirm Password',
            pass_placeholder:'Please input a password',
            repass_placeholder:'Please enter the password again',
            repass_wrong_placeholder:'The passwords entered twice are inconsistent!',
        }
    }
}